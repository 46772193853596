/**
 *@param {
  show = false,
  title = 'Title', 
  handleClose, 
  data = {},
  Modelbody,
  buttonText = ['Cancel', 'Save'],
}
 */

import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import React, {memo} from 'react';
import { Button } from 'react-bootstrap';
import './dialog.style.scss';
import '../Button/Button.style.scss';

 const  ScrollDialog = (props: any) =>{

  const scroll = 'paper';
  // const [scroll, setScroll] = React.useState('paper');     // Scroll type can be paper or body type.

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (props.show) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [props.show]);
  return (
    <div>
      {
        props.show ?
      <Dialog
        fullScreen = {false}
        fullWidth
        maxWidth = {props.maxWidth? props.maxWidth : 'sm'}
        open={props.show}
        onClose={props.crossMark ? null : props.handleClose}
        scroll={scroll}
        className = {`${props.styleClass}`}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" className="d-flex justify-content-between align-items-center dialog-title">
          <div>
          <p className="control-bold"  style={{ marginRight:"40px"}}>{props.title}</p>
          {/* { props.subTitle && <p className="dialog-subTitle"  style={{ margin:"auto"}}>{props.subTitle}</p> } */}
          </div>
          {
            !props.crossMark &&
          <p className ="cancel-popup" onClick={props.handleClose}><i className='icon-highlight_remove'></i></p> 
          }
        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'}
        >
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            component ='div'
          >
            {props.children}
          </DialogContentText>
        </DialogContent>
        {
          props?.ErrorText ?
          <p className="d-block invalid-feedback text-center">{props?.ErrorText}</p>
          : null
        }
        {
          props.buttonText?.length > 0 &&
          <DialogActions>
          {
            props.version === 'second' ?
            <>
              {
                props.buttonText.length > 1 && <Button onClick={props.saveChanges} disabled={props.disabled ? props.disabled : false} variant="contained" className="button-var2">{props.buttonText[1]}</Button>
              }
              <Button onClick={props.handleClose} variant="outlined" className="button-var3">{props.buttonText[0]}</Button>

            </>
            :
            <>
            <Button onClick={props.handleClose} variant="outlined" className="button-var3">{props.buttonText[0]}</Button>
              {
                props.buttonText.length > 1 && <Button onClick={props.saveChanges} disabled={props.disabled ? props.disabled : false} variant="contained" className="button-var2">{props.buttonText[1]}</Button>
              }
            </>
          }
        </DialogActions>
       }
      </Dialog>
      : null
      }
    </div>
  );
}

export  const InfoDialog = (props:any) => {

  const scroll = 'paper';
  // const [scroll, setScroll] = React.useState('paper');     // Scroll type can be paper or body type.

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (props.show) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [props.show]);
  return (
    <div>
      {
        props.show ?
      <Dialog
        fullScreen = {false}
        fullWidth
        maxWidth = {props.maxWidth? props.maxWidth : 'sm'}
        open={props.show}
        onClose={props.crossMark ? null : props.handleClose}
        scroll={scroll}
        className = {`${props.styleClass}`}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" className="d-flex justify-content-between align-items-center dialog-title">
          <div>
          <p className="control-bold"  style={{ marginRight:"40px"}}>{props.title}</p>
          {/* { props.subTitle && <p className="dialog-subTitle"  style={{ margin:"auto"}}>{props.subTitle}</p> } */}
          </div>
          {
            !props.crossMark &&
          <span className ="cancel-popup" onClick={props.handleClose}><i className='icon-highlight_remove'></i></span> 
          }
        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'}
        >
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            component ='div'
          >
            {props.children}
          </DialogContentText>
        </DialogContent>
        {
          props?.ErrorText ?
          <p className="d-block invalid-feedback text-center">{props?.ErrorText}</p>
          : null
        }
        {
          props.buttonText?.length > 0 &&
          <DialogActions>
            <>
            {props.closeButtonText?.length > 0 && (
              <Button onClick={props.handleClose} variant="contained" className={props.closeBtnStyle ? props.closeBtnStyle : "button-var5_close"}>{props.closeButtonText}</Button>
            )}
                <Button onClick={props.saveChanges} disabled={props.disabled ? props.disabled : false} variant="contained" className={props.btnStyle ? props.btnStyle : "save-btn-var3"}>{props.buttonText}</Button>
            </>
        </DialogActions>
       }
      </Dialog>
      : null
      }
    </div>
  );
}
export default memo(ScrollDialog);

